// Libraries
import currency from 'currency.js';
import _ from 'lodash';

const convertToCents = (string: currency.Any | undefined | null): number => {
  // currency.js types say we _should not_ be passing in 'undefined', but our code does... The
  // library returns 0 in this case
  // @ts-ignore
  return currency(string).intValue;
};

type formatForNegativeArgs = {
  text: string;
  isNegative: boolean;
};

const _formatForNegative = ({text, isNegative}: formatForNegativeArgs): string => {
  if (isNegative) {
    return `(${text})`;
  }
  return text;
};

type displayOptions = {
  shouldHideCentsIfZero?: boolean;
};

/**
 * Options:
 * shouldHideCentsIfZero: hide cents if it would be '.00'.
 */
const display = (cents: number, options: displayOptions = {}): string => {
  const formatted = currency(cents / 100).format(true);
  if (options.shouldHideCentsIfZero) {
    return formatted.replace(/\.00$/, '');
  } else {
    return formatted;
  }
};

type formatArgs = {
  value: number;
  shouldHideCentsIfZero: boolean;
};

const format = ({value, shouldHideCentsIfZero}: formatArgs): string => {
  if (_.isNil(value)) {
    return '';
  }
  const displayValue = display(Math.abs(value), {shouldHideCentsIfZero});
  return _formatForNegative({text: displayValue, isNegative: value < 0});
};

type formatRangeArgs = {
  min: number;
  max: number;
  shouldHideCentsIfZero: boolean;
};

const formatRange = ({min, max, shouldHideCentsIfZero}: formatRangeArgs): string => {
  // Return empty string if missing min
  if (_.isNil(min)) {
    return '';
  }
  // Return single value if missing max or if max and min are the same.
  // A zero max is the same as no max.
  if (!max || min === max) {
    return format({value: min, shouldHideCentsIfZero});
  }

  const displayMin = display(Math.abs(min), {shouldHideCentsIfZero});
  const displayMax = display(Math.abs(max), {shouldHideCentsIfZero});
  // Return a single value in parentheses if only one value is negative
  // Example: "($20) - $100"
  if (min > 0 && max < 0) {
    return `${_formatForNegative({text: displayMax, isNegative: max < 0})} - ${displayMin}`;
  }
  if (min < 0 && max > 0) {
    return `${_formatForNegative({text: displayMin, isNegative: min < 0})} - ${displayMax}`;
  }

  // This is a standard range that we expect most cases to be. It will
  // be surrounded in parentheses if it is negative.
  // Example: "$50 - $200" for a charge
  // Example: "($10 - $80)" for a discount
  return _formatForNegative({text: `${displayMin} - ${displayMax}`, isNegative: min < 0});
};

// For use with forms and mutations.
const toForm = (cents: number | '' | null | undefined, options: displayOptions = {}): string => {
  // `0` should be allowed to be turned into `$0.00`.
  if (cents === null || cents === undefined || cents === '') {
    return '';
  }
  return Currency.display(cents, options);
};

// We provide multiple overloads so that the return type is more specific.
// For existing instances that use toMutation without isNullAllowed, the return type is number.
// This allows downstream types to maintain the type of number instead of number | null.
function toMutation(string: currency.Any | undefined | null): number;
function toMutation(string: currency.Any | undefined | null, isNullAllowed: boolean): number | null;
function toMutation(
  string: currency.Any | undefined | null,
  isNullAllowed?: boolean,
): number | null {
  if (isNullAllowed && !string) {
    return null;
  }
  return Currency.convertToCents(string);
}

const Currency = {
  convertToCents,
  display,
  format,
  formatRange,
  toForm,
  toMutation,
};

export default Currency;
