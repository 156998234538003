// Supermove
import {useMountEffect, useState} from '@supermove/hooks';
import {ExecutionEnvironment} from '@supermove/sdk';

const useBrainfishWidget = ({apiKey, isVisible, onOpenChat}) => {
  const [brainfish, setBrainfish] = useState(null);

  useMountEffect(() => {
    // On server side rendering, this hook doesn't do anything. Need to exit early and also use
    // inline requires in order to avoid importing the widget and accesing 'window' on the server.
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }

    const Brainfish = require('@brainfish-ai/web-widget').default;

    // Initialize the Brainfish module.
    Brainfish.Widgets.init({
      widgetKey: apiKey,
      overrides: {
        nextBestActions: [
          {
            type: 'callback',
            label: 'Chat with Support',
            value: ({query, answer}) => {
              // Close the widget before opening the chat widget.
              Brainfish.HelpWidget.close();

              // Open chat widget and prepopulate with question asked.
              onOpenChat({query});
            },
          },
        ],
      },
    });

    // Once the module is loaded, save it in state to be used in handlers.
    setBrainfish(Brainfish);
  });

  return {
    handleOpen: () => {
      if (brainfish) {
        brainfish.HelpWidget.open();
      }
    },
  };
};

export default useBrainfishWidget;
